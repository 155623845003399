<template>
  <div class="app align-items-center">
    <vue-snotify></vue-snotify>
    <div class="container mt-3">
      <img class="navbar-brand-full" src="img/logo.png" width="300" height="44" alt="Inspiring Hope and Freedom">
      <b-card class="mt-3">
        <h1>Privacy Policy</h1>
        <p>Last updated: June 05, 2020</p>

        <p>This Privacy Policy describes Our policies and procedures on
        the collection, use and disclosure of Your information when You use the Service
        and tells You about Your privacy rights and how the law protects You.</p>

        <p>We use Your Personal data to provide and improve the
        Service. By using the Service, You agree to the collection and use of
        information in accordance with this Privacy Policy.</p>

        <h2>Interpretation and Definitions</h2>

        <h3>Interpretation</h3>

        <p>The words of which the initial letter is capitalized have
        meanings defined under the following conditions. The following definitions
        shall have the same meaning regardless of whether they appear in singular or in
        plural.</p>

        <h3>Definitions</h3>

        <p>For the purposes of this Privacy Policy:</p>

        <p class="indent mb-1"><b>You</b>
        means the individual accessing or using the Service, or the company, or other
        legal entity on behalf of which such individual is accessing or using the
        Service, as applicable.</p>

        <p class="indent mb-1"><b>Company</b>
        (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;
        or &quot;Our&quot; in this Agreement) refers to IHF, LLC, 1459 N 971 Rd.</p>

        <p class="indent mb-1"><b>Affiliate</b>
        means an entity that controls, is controlled by or is under common control with
        a party, where &quot;control&quot; means ownership of 50% or more of the
        shares, equity interest or other securities entitled to vote for election of
        directors or other managing authority.</p>

        <p class="indent mb-1"><b>Account</b>
        means a unique account created for You to access our Service or parts of our
        Service.</p>

        <p class="indent mb-1"><b>Website</b>
        refers to Inspiring Hope &amp; Freedom, accessible from <a
        href="https://inspiringhopeandfreedom.com"><span style='color:windowtext;
        text-decoration:none;text-underline:none'>https://inspiringhopeandfreedom.com</span></a></p>

        <p class="indent mb-1"><b>Service</b>
        refers to the Website.</p>

        <p class="indent mb-1"><b>Country</b>
        refers to: Kansas, United States</p>

        <p class="indent mb-1"><b>Service
        Provider</b> means any natural or legal person who processes the data on behalf
        of the Company. It refers to third-party companies or individuals employed by
        the Company to facilitate the Service, to provide the Service on behalf of the
        Company, to perform services related to the Service or to assist the Company in
        analyzing how the Service is used.</p>

        <p class="indent mb-1"><b>Third-party
        Social Media Service</b> refers to any website or any social network website
        through which a User can log in or create an account to use the Service.</p>

        <p class="indent mb-1"><b>Personal
        Data</b> is any information that relates to an identified or identifiable
        individual.</p>

        <p class="indent mb-1">For the purposes of the CCPA, Personal Data
        means any information that identifies, relates to, describes or is capable of
        being associated with, or could reasonably be linked, directly or indirectly,
        with You.</p>

        <p class="indent mb-1"><b>Cookies</b>
        are small files that are placed on Your computer, mobile device or any other
        device by a website, containing the details of Your browsing history on that
        website among its many uses.</p>

        <p class="indent mb-1"><b>Device</b>
        means any device that can access the Service such as a computer, a cellphone or
        a digital tablet.</p>

        <p class="indent mb-1"><b>Usage
        Data</b> refers to data collected automatically, either generated by the use of
        the Service or from the Service infrastructure itself (for example, the
        duration of a page visit).</p>

        <p class="indent mb-1"><b>Do
        Not Track</b> (DNT) is a concept that has been promoted by US regulatory
        authorities, in particular the U.S. Federal Trade Commission (FTC), for the
        Internet industry to develop and implement a mechanism for allowing internet
        users to control the tracking of their online activities across websites.</p>

        <p class="indent mb-1"><b>Business</b>,
        for the purpose of the CCPA (California Consumer Privacy Act), refers to the
        Company as the legal entity that collects Consumers' personal information and
        determines the purposes and means of the processing of Consumers' personal
        information, or on behalf of which such information is collected and that
        alone, or jointly with others, determines the purposes and means of the
        processing of consumers' personal information, that does business in the State
        of California.</p>

        <p class="indent mb-1"><b>Consumer</b>,
        for the purpose of the CCPA (California Consumer Privacy Act), means a natural
        person who is a California resident. A resident, as defined in the law,
        includes (1) every individual who is in the USA for other than a temporary or
        transitory purpose, and (2) every individual who is domiciled in the USA who is
        outside the USA for a temporary or transitory purpose.</p>

        <p class="indent mb-4"><b>Sale</b>,
        for the purpose of the CCPA (California Consumer Privacy Act), means selling,
        renting, releasing, disclosing, disseminating, making available, transferring,
        or otherwise communicating orally, in writing, or by electronic or other means,
        a Consumer's Personal information to another business or a third party for
        monetary or other valuable consideration.</p>

        <h2>Collecting and Using Your Personal Data</h2>

        <h3>Types of Data Collected</h3>

        <h4>Personal Data</h4>

        <p class=MsoNormal>While using Our Service, We may ask You to provide Us with
        certain personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is not
        limited to:</p>

        <p class="indent mb-1">Email address</p>

        <p class="indent mb-1">First name and last name</p>

        <p class="indent mb-1">Phone number</p>

        <p class="indent mb-1">Address, State, Province, ZIP/Postal code,
        City</p>

        <p class="indent mb-1">Financial Information – including but not
        limited to income, debts and assets</p>

        <p class="indent mb-4">Usage Data</p>

        <h4>Usage Data</h4>

        <p>Usage Data is collected automatically when using the
        Service.</p>

        <p>Usage Data may include information such as Your Device's
        Internet Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit, the time
        spent on those pages, unique device identifiers and other diagnostic data.</p>

        <p>When You access the Service by or through a mobile device,
        We may collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the IP
        address of Your mobile device, Your mobile operating system, the type of mobile
        Internet browser You use, unique device identifiers and other diagnostic data.</p>

        <p>We may also collect information that Your browser sends
        whenever You visit our Service or when You access the Service by or through a
        mobile device.</p>

        <h4>Tracking Technologies and Cookies</h4>

        <p>We use Cookies and similar tracking technologies to track
        the activity on Our Service and store certain information. Tracking
        technologies used are beacons, tags, and scripts to collect and track
        information and to improve and analyze Our Service.</p>

        <p>You can instruct Your browser to refuse all Cookies or to
        indicate when a Cookie is being sent. However, if You do not accept Cookies,
        You may not be able to use some parts of our Service.</p>

        <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
        Cookies. Persistent Cookies remain on your personal computer or mobile device
        when You go offline, while Session Cookies are deleted as soon as You close
        your web browser. Learn more about cookies: <a
        href="https://www.termsfeed.com/blog/cookies/"><span style='color:windowtext;
        text-decoration:none;text-underline:none'>All About Cookies</span></a>.</p>

        <p>We use both session and persistent Cookies for the purposes
        set out below:</p>

        <p class="indent mb-1"><b>Necessary
        / Essential Cookies</b></p>

        <p class="indent mb-1">Type: Session Cookies</p>

        <p class="indent mb-1">Administered by: Us</p>

        <p class="indent mb-1">Purpose: These Cookies are essential to
        provide You with services available through the Website and to enable You to
        use some of its features. They help to authenticate users and prevent fraudulent
        use of user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You with those
        services.</p>

        <p class="indent mb-1"><b>Cookies
        Policy / Notice Acceptance Cookies</b></p>

        <p class="indent mb-1">Type: Persistent Cookies</p>

        <p class="indent mb-1">Administered by: Us</p>

        <p class="indent mb-1">Purpose: These Cookies identify if users
        have accepted the use of cookies on the Website.</p>

        <p class="indent mb-1"><b>Functionality
        Cookies</b></p>

        <p class="indent mb-1">Type: Persistent Cookies</p>

        <p class="indent mb-1">Administered by: Us</p>

        <p class="indent mb-1">Purpose: These Cookies allow us to remember
        choices You make when You use the Website, such as remembering your login
        details or language preference. The purpose of these Cookies is to provide You
        with a more personal experience and to avoid You having to re-enter your
        preferences every time You use the Website.</p>

        <p class="indent mb-1"><b>Tracking
        and Performance Cookies</b></p>

        <p class="indent mb-1">Type: Persistent Cookies</p>

        <p class="indent mb-1">Administered by: Third-Parties</p>

        <p class="indent mb-4">Purpose: These Cookies are used to track
        information about traffic to the Website and how users use the Website. The
        information gathered via these Cookies may directly or indirectly identify you
        as an individual visitor. This is because the information collected is
        typically linked to a pseudonymous identifier associated with the device you
        use to access the Website. We may also use these Cookies to test new
        advertisements, pages, features or new functionality of the Website to see how
        our users react to them.</p>

        <p>For more information about the cookies we use and your
        choices regarding cookies, please visit our Cookies Policy or the Cookies
        section of our Privacy Policy.</p>

        <h3>Use of Your Personal Data</h3>

        <p>The Company may use Personal Data for the following
        purposes:</p>

        <ul>
          <li>
            <b>To
          provide and maintain our Service</b>, including to monitor the usage of our
          Service.</li>

          <li>
            <b>To manage
          Your Account:</b> to manage Your registration as a user of the Service. The
          Personal Data You provide can give You access to different functionalities of
          the Service that are available to You as a registered user.</li>

          <li>
            <b>For the
          performance of a contract:</b> the development, compliance and undertaking of
          the purchase contract for the products, items or services You have purchased or
          of any other contract with Us through the Service.</li>

          <li>
            <b>To
          contact You:</b> To contact You by email, telephone calls, SMS, or other
          equivalent forms of electronic communication, such as a mobile application's
          push notifications regarding updates or informative communications related to
          the functionalities, products or contracted services, including the security
          updates, when necessary or reasonable for their implementation.</li>

          <li>
            <b>To
          provide You</b> with news, special offers and general information about other
          goods, services and events which we offer that are similar to those that you
          have already purchased or enquired about unless You have opted not to receive
          such information.</li>

          <li>
            <b>To manage
          Your requests:</b> To attend and manage Your requests to Us.</li>
        </ul>

        <p>We may share your personal information in the following
        situations:</p>

        <ul>
          <li><b>With
          Service Providers:</b> We may share Your personal information with Service
          Providers to monitor and analyze the use of our Service, for payment
          processing, to contact You.</li>

          <li><b>For
          Business transfers:</b> We may share or transfer Your personal information in
          connection with, or during negotiations of, any merger, sale of Company assets,
          financing, or acquisition of all or a portion of our business to another
          company.</li>

          <li><b>With
          Affiliates:</b> We may share Your information with Our affiliates, in which
          case we will require those affiliates to honor this Privacy Policy. Affiliates
          include Our parent company and any other subsidiaries, joint venture partners
          or other companies that We control or that are under common control with Us.</li>

          <li><b>With
          Business partners:</b> We may share Your information with Our business partners
          to offer You certain products, services or promotions.</li>

          <li><b>With other
          users:</b> when You share personal information or otherwise interact in the
          public areas with other users, such information may be viewed by all users and
          may be publicly distributed outside. If You interact with other users or
          register through a Third-Party Social Media Service, Your contacts on the
          Third-Party Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to view
          descriptions of Your activity, communicate with You and view Your profile.</li>
        </ul>

        <h3>Retention of Your Personal Data</h3>

        <p>The Company will retain Your Personal Data only for as long
        as is necessary for the purposes set out in this Privacy Policy. We will retain
        and use Your Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to comply with
        applicable laws), resolve disputes, and enforce our legal agreements and
        policies.</p>

        <p>The Company will also retain Usage Data for internal
        analysis purposes. Usage Data is generally retained for a shorter period of
        time, except when this data is used to strengthen the security or to improve
        the functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.</p>

        <h3>Transfer of Your Personal Data</h3>

        <p>Your information, including Personal Data, is processed at
        the Company's operating offices and in any other places where the parties
        involved in the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your state,
        province, country or other governmental jurisdiction where the data protection
        laws may differ than those from Your jurisdiction.</p>

        <p>Your consent to this Privacy Policy followed by Your
        submission of such information represents Your agreement to that transfer.</p>

        <p>The Company will take all steps reasonably necessary to
        ensure that Your data is treated securely and in accordance with this Privacy
        Policy and no transfer of Your Personal Data will take place to an organization
        or a country unless there are adequate controls in place including the security
        of Your data and other personal information.</p>

        <h3>Disclosure of Your Personal Data</h3>

        <h4>Business Transactions</h4>

        <p>If the Company is involved in a merger, acquisition or asset
        sale, Your Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

        <h4>Law enforcement</h4>

        <p>Under certain circumstances, the Company may be required to
        disclose Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).</p>

        <h4>Other legal requirements</h4>

        <p>The Company may disclose Your Personal Data in the good
        faith belief that such action is necessary to:</p>

        <ul>
          <li>Comply with a legal obligation</li>

          <li>Protect and defend the rights or property of the
          Company</li>

          <li>Prevent or investigate possible wrongdoing in
          connection with the Service</li>

          <li>Protect the personal safety of Users of the
          Service or the public</li>

          <li>Protect against legal liability</li>
        </ul>

        <h3>Security of Your Personal Data</h3>

        <p>The security of Your Personal Data is important to Us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While We strive to use commercially
        acceptable means to protect Your Personal Data, We cannot guarantee its
        absolute security.</p>

        <h2>Detailed Information on the Processing of Your Personal Data</h2>

        <p>Service Providers have access to Your Personal Data only to
        perform their tasks on Our behalf and are obligated not to disclose or use it
        for any other purpose.</p>

        <h3>Analytics</h3>

        <p>We may use third-party Service providers to monitor and
        analyze the use of our Service.</p>

        <p><b>mailgun.com</b></p>

        <p>Their Privacy Policy can be viewed at <a
        href="https://www.mailgun.com/privacy-policy/"><span style='color:windowtext;
        text-decoration:none;text-underline:none'>https://www.mailgun.com/privacy-policy/</span></a></p>

        <h3>Payments</h3>

        <p>We may provide paid products and/or services within the
        Service. In that case, we may use third-party services for payment processing
        (e.g. payment processors).</p>

        <p>We will not store or collect Your payment card details. That
        information is provided directly to Our third-party payment processors whose
        use of Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by the PCI
        Security Standards Council, which is a joint effort of brands like Visa,
        Mastercard, American Express and Discover. PCI-DSS requirements help ensure the
        secure handling of payment information.</p>

        <p><b>Card Connect Corp</b></p>

        <p>Their Privacy Policy can be viewed at <a
        href="https://cardconnect.com/privacy-policy"><span style='color:windowtext;
        text-decoration:none;text-underline:none'>https://cardconnect.com/privacy-policy</span></a></p>

        <h2>CCPA Privacy</h2>

        <h3>Your Rights under the CCPA</h3>

        <p>Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:</p>

        <ul>
          <li>
            <b>The right
          to notice.</b> You must be properly notified which categories of Personal Data
          are being collected and the purposes for which the Personal Data is being used.</li>

          <li>
            <b>The right
          to access / the right to request.</b> The CCPA permits You to request and
          obtain from the Company information regarding the disclosure of Your Personal
          Data that has been collected in the past 12 months by the Company or its
          subsidiaries to a third-party for the third party's direct marketing purposes.</li>

          <li>
            <b>The right
          to say no to the sale of Personal Data.</b> You also have the right to ask the
          Company not to sell Your Personal Data to third parties. You can submit such a
          request by visiting our &quot;Do Not Sell My Personal Information&quot; section
          or web page.</li>

          <li>
            <b>The right
          to know about Your Personal Data.</b> You have the right to request and obtain
          from the Company information regarding the disclosure of the following:

            <ul>
              <li>The categories of Personal Data collected</li>

              <li>The sources from which the Personal Data was
              collected</li>

              <li>The business or commercial purpose for
              collecting or selling the Personal Data</li>

              <li>Categories of third parties with whom We share
              Personal Data</li>

              <li>The specific pieces of Personal Data we
              collected about You</li>
            </ul>
          </li>
          <li>
            <b>The right
          to delete Personal Data.</b> You also have the right to request the deletion of
          Your Personal Data that have been collected in the past 12 months.</li>

          <li>
            <b>The right
          not to be discriminated against.</b> You have the right not to be discriminated
          against for exercising any of Your Consumer's rights, including by:

            <ul>
              <li>Denying goods or services to You</li>

              <li>Charging different prices or rates for goods or
              services, including the use of discounts or other benefits or imposing
              penalties</li>

              <li>Providing a different level or quality of goods
              or services to You</li>

              <li>Suggesting that You will receive a different
              price or rate for goods or services or a different level or quality of goods or
              services.</li>
            </ul>
            </li>
        </ul>

        <h3>Exercising Your CCPA Data Protection Rights</h3>

        <p>In order to exercise any of Your rights under the CCPA, and
        if you are a California resident, You can email or call us or visit our
        &quot;Do Not Sell My Personal Information&quot; section or web page.</p>

        <p>The Company will disclose and deliver the required
        information free of charge within 45 days of receiving Your verifiable request.
        The time period to provide the required information may be extended once by an
        additional 45 days when reasonable necessary and with prior notice.</p>

        <h3>Do Not Sell My Personal Information</h3>

        <p>We do not sell personal information. However, the Service
        Providers we partner with (for example, our advertising partners) may use
        technology on the Service that &quot;sells&quot; personal information as
        defined by the CCPA law.</p>

        <p>If you wish to opt out of the use of your personal
        information for interest-based advertising purposes and these potential sales
        as defined under CCPA law, you may do so by following the instructions below.</p>

        <p>Please note that any opt out is specific to the browser You
        use. You may need to opt out on every browser that you use.</p>

        <h4>Website</h4>

        <p>You can opt out of receiving ads that are personalized as
        served by our Service Providers by following our instructions presented on the
        Service:</p>

        <ul>
          <li>From Our &quot;Cookie Consent&quot; notice
          banner</li>

          <li>Or from Our &quot;CCPA Opt-out&quot; notice
          banner</li>

          <li>Or from Our &quot;Do Not Sell My Personal
          Information&quot; notice banner</li>

          <li>Or from Our &quot;Do Not Sell My Personal
          Information&quot; link</li>
        </ul>

        <p>The opt out will place a cookie on Your computer that is
        unique to the browser You use to opt out. If you change browsers or delete the
        cookies saved by your browser, you will need to opt out again.</p>

        <h4>Mobile Devices</h4>

        <p>Your mobile device may give you the ability to opt out of
        the use of information about the apps you use in order to serve you ads that
        are targeted to your interests:</p>

        <ul>
          <li>&quot;Opt out of Interest-Based Ads&quot; or
          &quot;Opt out of Ads Personalization&quot; on Android devices</li>

          <li>&quot;Limit Ad Tracking&quot; on iOS devices</li>
        </ul>

        <p>You can also stop the collection of location information
        from Your mobile device by changing the preferences on your mobile device.</p>

        <h2>&quot;Do Not Track&quot; Policy as Required by California
        Online Privacy Protection Act (CalOPPA)</h2>

        <p>Our Service does not respond to Do Not Track signals.</p>

        <p>However, some third party websites do keep track of Your
        browsing activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want to be
        tracked. You can enable or disable DNT by visiting the preferences or settings
        page of Your web browser.</p>

        <h2>Your California Privacy Rights (California's Shine the Light
        law)</h2>

        <p>Under California Civil Code Section 1798 (California's Shine
        the Light law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal Data
        with third parties for the third parties' direct marketing purposes.</p>

        <p>If you'd like to request more information under the
        California Shine the Light law, and if you are a California resident, You can
        contact Us using the contact information provided below.</p>

        <h2>California Privacy Rights for Minor Users (California
        Business and Professions Code Section 22581)</h2>

        <p>California Business and Professions Code section 22581 allow
        California residents under the age of 18 who are registered users of online
        sites, services or applications to request and obtain removal of content or
        information they have publicly posted.</p>

        <p>To request removal of such data, and if you are a California
        resident, You can contact Us using the contact information provided below, and
        include the email address associated with Your account.</p>

        <p>Be aware that Your request does not guarantee complete or
        comprehensive removal of content or information posted online and that the law
        may not permit or require removal in certain circumstances.</p>

        <h2>Links to Other Websites</h2>

        <p>Our Service may contain links to other websites that are not
        operated by Us. If You click on a third party link, You will be directed to
        that third party's site. We strongly advise You to review the Privacy Policy of
        every site You visit.</p>

        <p>We have no control over and assume no responsibility for the
        content, privacy policies or practices of any third party sites or services.</p>

        <h2>Changes to this Privacy Policy</h2>

        <p>We may update our Privacy Policy from time to time. We will
        notify You of any changes by posting the new Privacy Policy on this page.</p>

        <p>We will let You know via email and/or a prominent notice on
        Our Service, prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.</p>

        <p>You are advised to review this Privacy Policy periodically
        for any changes. Changes to this Privacy Policy are effective when they are
        posted on this page.</p>

        <h2>Contact Us</h2>

        <p>If you have any questions about this Privacy Policy, You can
        contact us:</p>

        <ul>
          <li>By visiting this page on our website: <a
          href="https://inspiringhopeandfreedom.com/privacy-policy"><span style='color:windowtext;
          text-decoration:none;text-underline:none'>https://inspiringhopeandfreedom.com/privacy-policy</span></a></li>
        </ul>
      </b-card>
    </div>
  </div>

</template>

<script>

export default {
  name: 'PrivacyPolicy',
  data () {
    return {}
  },
  computed: {}
}
</script>

<style lang="scss">
h2 {
  border-bottom: 1px solid #4F81BD;
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}

p {
  &.indent {
    margin-left: 2rem;
  }
}
</style>
